import React from "react";
import { useState } from "react";
import PricingTable from ".";
import Section from "../Div";
// import Spacing from "../Spacing";
import Slider from "react-slick";

export const packages = [
  {
    title: "COMPLETE REVIVAL",
    price: "200",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Deep Clean Interior & Exterior:</b> A refresh for your entire vehicle.</span>,
      <span><b>Shampoo & Vacuum:</b> Interior carpets and upholstery restored to like-new condition.</span>,
      <span><b>Dash & Panel Restoration:</b> Cleaned, dressed, and rejuvenated.</span>,
      <span><b>Exterior Decontamination:</b> Removal of dirt, grime, and contaminants.</span>,
      <span><b>Tire & Wheel Cleaning:</b> Tires and rims detailed for a complete look.</span>,
    ],
  },
  {
    title: "ENGINEERED SHINE",
    price: "250",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Engine Bay Detailing:</b> Degreased, cleaned, and dressed for a showroom finish.</span>,
      <span><b>Deep Clean Interior & Exterior:</b> A refresh for your entire vehicle.</span>,
      <span><b>Exterior Decontamination:</b> Removal of dirt, grime, and contaminants.</span>,
      <span><b>Detailed to Perfection:</b> Hand washed to restore shine and clarity.</span>,
      <span><b>Tire & Wheel Cleaning:</b> Tires and rims detailed for a complete look.</span>,
    ],
  },
  {
    title: "GLOSS GUARD",
    price: "300",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Deep Clean Interior & Exterior:</b> A refresh for your entire vehicle.</span>,
      <span><b>Paint Decontamination:</b> Clay bar and surface prep for a smooth base.</span>,
      <span><b>Wax Sealant Protection:</b> High-gloss finish with 3 months of paint protection.</span>,
      <span><b>Polished Finish:</b> Enhances paint clarity and depth.</span>,
      <span><b>Rims & Tires Dressed:</b> A sleek, professional appearance.</span>,
    ],
  },
  {
    title: "GLOSS GUARD PRO",
    price: "600",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Deep Clean Interior & Exterior:</b> A refresh for your entire vehicle.</span>,
      <span><b>Ceramic Paint Sealant:</b> Long-lasting 1-year protection against exterior elements.</span>,
      <span><b>High-Gloss Finish:</b> Provides incredible shine and water-beading properties.</span>,
      <span><b>Polish & Restoration:</b> Light polish to restore faded paint.</span>,
      <span><b>Wheel & Trim Detailing:</b> Finished to perfection for a fresh look.</span>,
    ],
  },
  {
    title: "CERAMIC LUXE",
    price: "1,000",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Full Interior Restoration:</b> Shampoo, vacuum, and dash rejuvenation.</span>,
      <span><b>Complete Exterior Detail:</b> Deep clean, clay bar, and surface prep.</span>,
      <span><b>3-Year Ceramic Coating:</b> Durable protection with exceptional gloss.</span>,
      <span><b>UV & Chemical Resistance:</b> Protects paint against harsh environmental factors.</span>,
      <span><b>Water-Beading Finish:</b> Hydrophobic properties for easier maintenance.</span>,
    ],
  },
  {
    title: "CERAMIC ULTRA",
    price: "1,500",
    currency: "$",
    timeline: "regular",
    features: [
      <span><b>Ultimate Interior & Exterior Care:</b> Full detail to rejuvenate every inch.</span>,
      <span><b>Advanced Surface Prep:</b> Clay bar, polish, and contaminant removal for perfection.</span>,
      <span><b>8-Year Ceramic Coating:</b> The highest level of durability and shine.</span>,
      <span><b>Extreme Hydrophobic Protection:</b> Water, dirt, and grime slide right off.</span>,
      <span><b>Luxury Finish:</b> Flawless appearance that turns heads for years to come.</span>,
    ],
  },
];

export default function PricingTableList() {
  const [tab, setTab] = useState("regular");
  /** Slider Settings **/
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: false,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li
          className={tab === "regular" ? "active" : ""}
          onClick={() => setTab("regular")}
        >
          Regular
        </li>
        <li
          className={tab === "loyalty" ? "active" : ""}
          // onClick={() => setTab("yearly")}
        >
          Loyalty
        </li>
      </ul>
      {tab === "regular" && (
        <Slider {...settings} className="cs-gap-12 cs-arrow_style4">
          {packages.map((item, index) => (
            <PricingTable
              key={index}
              title={item.title}
              price={item.price}
              currency="$"
              timeline="dollars"
              features={item.features}
              btnText="Purchase Now"
              btnLink="https://squareup.com/appointments/book/qbtgldoeb8f818/LXCQW9CATF21W/start"
            />
          ))}
        </Slider>
      )}
      {/* <Section className="row">
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Standard"
              price="29"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Standard"
              price="59"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Professional"
              price="199"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Professional"
              price="399"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Ultimate"
              price="299"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Ultimate"
              price="599"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
      </Section> */}
    </Section>
  );
}
